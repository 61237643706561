var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "q-form",
        { ref: "editForm" },
        [
          _c(
            "c-card",
            { staticClass: "cardClassDetailForm", attrs: { title: "상세" } },
            [
              _c(
                "template",
                { slot: "card-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _vm.editable && !_vm.disabled
                        ? _c("c-btn", {
                            attrs: {
                              isSubmit: _vm.isSave,
                              url: _vm.saveUrl,
                              param: _vm.targetDept,
                              mappingType: "PUT",
                              label: "저장",
                              icon: "save",
                            },
                            on: {
                              beforeAction: _vm.saveTargetDept,
                              btnCallback: _vm.saveTargetDeptCallback,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("template", { slot: "card-detail" }, [
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c("c-textarea", {
                      attrs: {
                        editable: _vm.editable,
                        disabled: _vm.disabled,
                        rows: 15,
                        label: "결과요약",
                        name: "resultRemark",
                      },
                      model: {
                        value: _vm.targetDept.resultRemark,
                        callback: function ($$v) {
                          _vm.$set(_vm.targetDept, "resultRemark", $$v)
                        },
                        expression: "targetDept.resultRemark",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c("c-upload", {
                      attrs: {
                        label: "결과보고서",
                        attachInfo: _vm.attachInfo,
                        editable: _vm.editable && !_vm.disabled,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }